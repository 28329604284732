import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import ImageCover from "../components/image-cover"
import RealisationSolution from "../components/realisation-solutions"
import LastRealisation from "../components/last-realisation"
import SEO from "../components/seo"

class WebMarketing extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Web Marketing" keywords={[
          "le marketing numérique","stratégie digitale","stratégie de marketing digital","formation marketing digitale","marketing et communication digitale","online marketing","stratégie de communication digitale","strategie digitale","content marketing","communication digitale","agence digitale","le marketing digitale","web marketing et communication digitale","marketing numérique","formation dans le digital","transformation digitale","strategie marketing digitale","formation web marketing","expérience client digitale","emploi digital"
        ]} />
        <main id="wall">
          
          { /* <section className="scroll-section"> */}
            <VideoCover
              title="Stratégies digitales"
              videoUrl="/video/test.mp4" 
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover 
              title="Référencement naturel (SEO)"
              content="Être en première page de Google est primordial pour votre activité, mais dompter le moteur de recherche n’est pas chose aisée et demande des compétences aiguisées. Notre expertise vous permettra d’optimiser le référencement naturel de votre site internet sur les moteurs de recherche. Nous assurons un suivi de son positionnement qui vous permettra d’attirer vers vos pages web un trafic qualifié. "
              image="/img/hero/seo1.jpg"
              classDiv="hero--seo"
              btn={{
                action: "/modern-contact?from=SEO",
                value: "DEMANDER UN DEVIS"
              }}
              
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Publicité sur les moteurs de recherche"
              content="Valoriser un lien sponsorisé dans la liste des résultats proposés par un moteur de recherche permet de gagner rapidement un fort trafic qualifié. Malgré la simplicité de l’interface de Google Ads, plusieurs détails sont à considérer pour assurer un retour sur investissement positif. Nous vous accompagnons dans le choix des mots clés, le ciblage, le paramétrage, l’optimisation et le reporting de votre campagne."
              image="/img/hero/sea.jpg"
              classDiv="hero--sea"
              btn={{
                action: "/modern-contact?from=Publicité-sur-les-moteurs-de-recherche",
                value: "DEMANDER UN DEVIS"
              }}
              
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Publicité sur les réseaux sociaux"
              content="À l'ère du big data, les entreprises ne peuvent plus faire l’impasse sur les réseaux sociaux pour communiquer. C’est, en fait, le moyen le moins cher et le plus efficace et le plus précis pour atteindre sa cible. Votre présence sur Facebook et ses congénères augmentera votre notoriété, générera du trafic et influencera votre position dans les résultats de recherche Google."
              image="/img/hero/social.jpg"
              classDiv="hero--social"
              btn={{
                action: "/modern-contact?from=Publicité-sur-les-médias-sociaux",
                value: "DEMANDER UN DEVIS"
              }}
            />
          {/* </section> */}

          <LastRealisation />
          
          <RealisationSolution />

        </main>


      </Layout>
    
  )

  constructor(props) {
    super(props);
    this.state = {
      wall: null
    }
  }

  componentDidMount = () => {
    // helpers.loadCSS(cssStyle, "#pagepilling")
  }

  componentWillUnmount = () => {
    // helpers.unloadCSS("#pagepilling")
  }

}

export default WebMarketing
